<template>
  <div>
    <b-table
      striped
      hover
      :items="photoCompetitionList"
      :fields="fields"
      :busy="isLoadingComponent"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(image)="{ item }">
        <img :src="item.image" class="image__table" alt="" />
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item :to="'/edit-competition/' + item.uuid">
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-dropdown-item>

          <b-dropdown-item v-if="item.type === 'article'" :to="'/list-post/' + item.uuid">
            <feather-icon icon="CreditCardIcon" class="mr-75" />
            List Article
          </b-dropdown-item>

          <b-dropdown-item v-if="item.type !== 'article'" :to="'/list-participant/' + item.uuid">
            <feather-icon icon="UsersIcon" class="mr-75" />
            List Participant
          </b-dropdown-item>

          <b-dropdown-item
            v-else
            :to="'/set-winner-competition/' + item.uuid"
          >
            <feather-icon icon="AwardIcon" class="mr-75" />
            Set Winner
          </b-dropdown-item>

          <b-dropdown-item @click="deleteItem(item.uuid)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    photoCompetition: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    photoCompetition(value) {
      this.photoCompetitionList = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
  },
  data() {
    return {
      moment,
      isLoadingComponent: false,
      currentPageList: 1,
      resultData: {},
      photoCompetitionList: [],
      fields: [
        { key: "no" },
        { key: "title" },
        { key: "type" },
        { key: "image" },
        { key: "actions" },
      ],
      //   filter: {
      //     order_type: "desc",
      //   },
    };
  },
  computed: {
    rows() {
      return this.photoCompetitionList.length;
    },
  },
};
</script>

<style lang="scss">
.image__table {
  width: 200px;
  height: 100px;
  object-fit: cover;
}
</style>
